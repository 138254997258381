import React, { useEffect } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import logo from '../Assets/image/DTELogo.png'
import logo2 from '../Assets/image/jayate.png'

function TopHeader() {
    useEffect(() => {
        const dropdown = document.querySelector('.goog-te-combo');
        if (dropdown) {
          dropdown.addEventListener('click', (e) => {
            e.preventDefault();
          });
        }
      }, []);
      
      const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            autoDisplay: true,
            includedLanguages: "en,hi,mr",
          },
          "google_translate_element"
        );
        
      };
      
      
      useEffect(() => {
        var addScript = document.createElement("script");
        addScript.async = true;
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      }, []);
    return (
        <Navbar bg="light" data-bs-theme="light">
            <Container>
                <Navbar.Brand href="/" className='ms-2'>
                    <img src='/LOGO-01.svg' className='navbarImageSize' />
                    <div className='ms-3'>
                        <p style={{ margin: 0, fontSize: '18px' }} className='topHeader-text'>Government of Maharashtra</p>
                        <p style={{ margin: 0, fontSize: '18px' }} className='topHeader-text'>Department of Higher and Technical Education</p>
                        <h3 style={{ margin: 0, fontSize: '26px' }} className='media-text'><strong>Swami Vivekanand Yuva Suraksha Yojana</strong></h3>
                        {/* <span style={{ margin: 0 ,fontSize:'13px' }}>Maharashtra State, India</span> */}
                    </div>
                </Navbar.Brand>
                <Nav className='ms-auto'>
                    {/* <Nav.Link href="/" active className='me-4  active-link'> */}
                        {/* <img src={logo2} className='media-nav-logo'></img> */}
                        <div id="google_translate_element" className='google-translate'></div>
                    {/* </Nav.Link> */}
                </Nav>
            </Container>
        </Navbar>
    )
}

export default TopHeader